// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBWHJKGbWx83jS-_lYblU3HG-Lv3kOnK8E',
  authDomain: 'bfluency-9ed7c.firebaseapp.com',
  projectId: 'bfluency-9ed7c',
  storageBucket: 'bfluency-9ed7c.appspot.com',
  messagingSenderId: '1053850634876',
  appId: '1:1053850634876:web:f039ac16392698c524d7f9',
  measurementId: 'G-R7FRW8D0P9',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

// export const loginWithLinkedin = () => {
//   const provider = new OAuthProvider('microsoft.com')
//   provider.addScope('mail.read')
//   const auth = getAuth()
//   return signInWithPopup(auth, provider)
//     .then((result) => {
//       // User is signed in.
//       // IdP data available in result.additionalUserInfo.profile.

//       // Get the OAuth access token and ID Token
//       const credential = OAuthProvider.credentialFromResult(result)
//       const accessToken = credential.accessToken
//       const idToken = credential.idToken
//       console.log(result)
//       return { ...result, credential, accessToken, idToken }
//     })
//     .catch((error) => {
//       console.log(error)
//     })
// }
