import * as React from 'react'
import axios from 'axios'
import PageFrame from 'primitives/layout/PageFrame'
import Button from 'primitives/buttons/Button'
import classNames from 'classnames'
import googleLogo from '../../assets/images/logos/google-logo.png'
import FormRules from 'utils/rules'
import { AppContext } from 'context/AppContext'
import { LinkedinOutlined } from '@ant-design/icons'
import { Form, Input, message, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom'
import {
    Card,
} from 'antd'

import styles from './Login.module.css'

const Divider = () => (
    <div className={styles.divider}></div>
)

const Signup = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [, setAppContextData] = React.useContext(AppContext)
    const history = useHistory()

    // React.useEffect(() => {
    //     const code = new URLSearchParams(location.search).get("code")
    //     console.log(code)
    //     if (code) {
    //         setLoading(true)
    //         axios.post(`${process.env.REACT_APP_SERVER}/auth/linkedin/token`, { access_token: code })
    //             .then(result => {
    //                 setLoading(false)
    //                 if (result.data) {
    //                     message.success(`Bienvenid@ ${result.data?.user?.displayName}`)
    //                     setAppContextData(result.data)
    //                     history.push('/perfil/mis-cursos')
    //                 }


    //             })
    //             .catch(err => {
    //                 console.error(err)
    //                 notification.error({
    //                     message: 'No se pudo iniciar sesión en esta red social',
    //                     description:
    //                         'Intenta con otro método',
    //                     duration: 0
    //                 });
    //                 setLoading(false)
    //             })
    //     }
    // }, [])

    const onFinish = values => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_SERVER}/auth/signup`, values)
            .then(({ data }) => {
                console.log(data)
                setLoading(false)
                if (data) {
                    message.success('¡Cuenta creada con exito!')
                    setAppContextData(data)
                    history.push('/profile')
                }
            })
            .catch(err => {
                setError(err.response?.data?.message)
                notification.error({
                    message: 'No se pudo crear la cuenta',
                    description: err.response?.data?.message ||
                        'Verifica tu usuario y contraseña porfavor.',
                    duration: 0
                });
                setLoading(false)
            })
    }


    const linkedinLogin = () => {
        // window.open(`${process.env.REACT_APP_CLIENT_TOKEN}`, "", "width=400,height=600,top=200,left=500")
        window.location.href = (`${process.env.REACT_APP_CLIENT_TOKEN}`)
    }

    return <PageFrame>
        <div className={styles.container}>
            <Card className={classNames(styles.card)}>
                <p className={classNames(styles.title)}>
                    Crea una cuenta
                </p>
                <span className={classNames(styles.subtitle)}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, turpis magna euismod turpis sed. Vitae odio est aliquam.
                </span>
                <div className={styles.buttons}>
                    <button className={styles.google}>
                        <img width={20} src={googleLogo} alt="google-logo" />{" "}<span>Google</span>
                    </button>
                    <button
                        onClick={linkedinLogin}
                        className={styles.in}>
                        <LinkedinOutlined />{" "}
                        <span> Linkedin</span>
                    </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><Divider /> <span className={styles.subtitle}>o</span> <Divider /></div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    requiredMark={false}
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            FormRules.email
                        ]}
                        help={error}
                    >
                        <Input onChange={() => setError(null)} placeholder="ejemplo@bfluency.com" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Contraseña"
                        rules={[
                            FormRules.password,
                            FormRules.passwordPattern
                        ]}
                    >
                        <Input.Password placeholder="********" />
                    </Form.Item>
                    <Form.Item
                        name="passwordTwo"
                        label="Confirma tu contraseña"
                        rules={[
                            FormRules.passwordMatch,
                            FormRules.passwordPattern
                        ]}
                    >
                        <Input.Password placeholder="********" />
                    </Form.Item>

                    <div className={styles.buttonContainer}>
                        <Button loading={loading} htmlType="submit" variantSize="large">
                            Crear cuenta
                        </Button>
                    </div>
                    <p>¿Ya tienes cuenta? <Link to="/login">Inicia sesión</Link></p>
                </Form>

            </Card>
        </div>
    </PageFrame >
}
export default Signup
