//@flow
import * as React from 'react'
import classNames from 'classnames'
import AdvancedCard from '../../../primitives/cards/AdvancedCard'
import PageFrame from 'primitives/layout/PageFrame'
import { Image } from 'antd'

import styles from './CoursesProfile.module.css'



type CoursesProfileProps = {
  name: string,
  coursesInProgress: number,
}

const cardsCourses = (
  <div className={classNames(styles.cardWrapper)}>
    <AdvancedCard
      img='https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/19621088_1334780026617382_7823503395334101987_o.jpeg?alt=media&token=50d41518-72e7-4dc5-87ee-e686e55683bd'
      name='Curso para líderes'
      description='Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus'
      progress={80}
      changeFunction={() => console.log('holi')}
    />
    <AdvancedCard
      img='https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/19621088_1334780026617382_7823503395334101987_o.jpeg?alt=media&token=50d41518-72e7-4dc5-87ee-e686e55683bd'
      name='Curso para líderes'
      description='Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus'
      progress={100}
      changeFunction={() => console.log('holi')}
    />
    <AdvancedCard
      img='https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/19621088_1334780026617382_7823503395334101987_o.jpeg?alt=media&token=50d41518-72e7-4dc5-87ee-e686e55683bd'
      name='Curso para líderes'
      description='Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus'
      progress={80}
      changeFunction={() => console.log('holi')}
    />
    <AdvancedCard
      img='https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/19621088_1334780026617382_7823503395334101987_o.jpeg?alt=media&token=50d41518-72e7-4dc5-87ee-e686e55683bd'
      name='Curso para líderes'
      description='Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus'
      progress={100}
      changeFunction={() => console.log('holi')}
    />
  </div>
)

const newProfile = (
  <div className={classNames(styles.newProfile)}>
    <Image
      preview={false}
      width={'176px'}
      height={'254px'}
      src='https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/no_courses_icon.svg?alt=media&token=15648924-8083-4a4c-8d51-8ffe937384f4'
    />
    <p className={classNames(styles.newProfileTitle)}> Ups! Aún no tienes cursos. </p>
  </div>
)

const CoursesProfile = ({ name = 'Laura', coursesInProgress = 4 }: CoursesProfileProps): React.Node => {

  return (
    <PageFrame variant="private">

      <div className={classNames(styles.coursesProfileContainer)}>
        <div className={classNames(styles.coursesProfileContent)}>
          <h2 className={classNames(styles.contentTitle)}>Cursos en progreso</h2>
          {!coursesInProgress ? newProfile : cardsCourses}
        </div>
      </div>
    </PageFrame>
  )
}

export default CoursesProfile
