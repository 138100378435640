//@flow
import * as React from 'react'
import classNames from 'classnames'
import { Card as AntCard } from 'antd'
import ProgressBar from '../progressBar/ProgressBar'

import styles from './AdvancedCard.module.css'

const { Meta } = AntCard;

type CourseCardProps = {
    img: string,
    name: string,
    description: string,
    progress: number,
    changeFunction?: mixed,
}


const AdvancedCard = ({img, name, description, progress, changeFunction}:CourseCardProps):React.Node => {
    return (
        <AntCard
         hoverable = {true}
         onClick = {changeFunction}
         className = {classNames(styles.container)}
         cover = {
             <img className={classNames(styles.img)} alt= 'course' src= {img}/>
         }
        >
            <Meta
                title = {<h3 className={styles.title}>{name}</h3>}
                description = {<p className={styles.description}>{description}</p>}
            />
            <ProgressBar progress={progress} />
        </AntCard>
    )
}

export default AdvancedCard
