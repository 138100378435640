//@flow
import * as React from 'react'
import classNames from 'classnames'
import styles from './Programas.module.css'
import { TeamOutlined, FlagOutlined, LineChartOutlined } from '@ant-design/icons'
import Button from 'primitives/buttons/Button'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom'
import PageFrame from 'primitives/layout/PageFrame'
const img = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fimg-5.png?alt=media&token=7761cb1d-d5ec-49d0-a0f4-43ffd388ed80"

const Programas = (): React.Node => {
    React.useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <PageFrame>
            <meta property="og:title" content="Sobre Potentiia " />
            <meta property="og:description"
                content="Acompañando a los equipos hasta su mejor versión a través de herramientas prácticas y aplicables provenientes del alto rendimiento deportivo." />

            <section className={classNames(styles.container)}>
                <section className={classNames(styles.programasContainer)}>
                    <div className={classNames(styles.info)}>
                        <Fade bottom>
                            <h2 className={classNames(styles.title)}>Programas Individuales</h2>
                            <p >Nuestros programas individuales son perfectos para toda aquella persona que quiera tomar el control de su desarrollo personal y profesional.
                            </p>

                            <Link to="/contacto">
                                <Button size="medium">Habla con nosotros</Button>
                            </Link>
                        </Fade>
                    </div>
                </section>
            </section>
            <div className={classNames(styles.box)}>
                <Fade bottom>
                    <div>
                        <h2 className={classNames(styles.titleDescription)}>
                            Programas Individuales Personalizados
                        </h2>
                        <p className={classNames(styles.text)}>
                            Estos programas son perfectos para toda aquella persona que quiera tomar el control de su desarrollo personal y profesional.

                            Nuestros programas van desde 6 hasta 12 sesiones individuales donde cada sesión recibirás una herramienta práctica que podrás usar de manera inmediata en tu día a día. <strong style={{ color: "#CB007B" }}>¿Cómo es el proceso?</strong>
                        </p>

                    </div>
                </Fade>
                <Fade bottom>
                    <section className={classNames(styles.content)} >
                        <img className={classNames(styles.cardImg)} src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fcircle1.png?alt=media&token=1fc8e9d6-390c-4246-a739-2245daadc8fd" />
                        <img className={classNames(styles.cardImg)} src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fcircle2.png?alt=media&token=3dd3acc8-7d64-48a0-b684-0a7f9f60de6d" />
                        <img className={classNames(styles.cardImg)} src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fcircle3.png?alt=media&token=59e361ec-9e5e-48b6-87dc-9eeff11c75d2" />
                        <img className={classNames(styles.cardImg)} src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fcircle4.png?alt=media&token=deb87c33-902c-4d64-8470-5a23a21cfe1a" />
                    </section>
                </Fade>
                <Fade bottom>
                    <div>

                        <p className={classNames(styles.text)}>
                            <strong>¡Contáctanos y descubre cómo podemos ayudarte a llegar a tu siguiente versión!</strong>
                        </p>
                        <br />
                        <Link to="/contacto">
                            <Button variantSize='medium'>Quiero saber más</Button>
                        </Link>
                    </div>
                </Fade>
            </div >

        </PageFrame>
    )
}

export default Programas