// FORM-RULES
const FormRules = {
  basic: {
    required: true,
    message: 'Completa este campo.',
  },
  email: {
    required: true,
    message: 'Por favor ingresa tu email.',
  },
  password: {
    required: true,
    message: 'Por favor ingresa tu contraseña.',
  },
  passwordPattern: {
    required: true,
    pattern: /(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,}$/,
    message: 'Por favor verifica que tu contraseña tenga 8 caracteres, una mayúscula y un número.',
  },
  passwordMatch: ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject('Tu contraseña no coincide.')
    },
  }),
}

export default FormRules
