//@flow
import * as React from 'react'
import classNames from 'classnames'
import { Image } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import Button from '../buttons/Button'

import styles from './Navbar.module.css'
import { AppContext } from '../../context/AppContext'
import logoColor from "../../assets/images/potentiia-color.png"
import logo from "../../assets/images/potentiia.png"


type NavBarProps = {|
    style?: 'light' | 'dark',
        variant ?: 'dark' | 'light',
        children ?: React.Node,
|}

const blackContent = (<div>contenido negro</div>)

const NavBar = ({
    style,
    variant = 'light',
}: NavBarProps): React.Node => {

    const [appData, , signOut] = React.useContext(AppContext)
    const [showMenu, setShow] = React.useState(false)

    const hideMenu = () => setShow(false)

    const whiteContent = (
        <div className={styles.lightNavBar}>
            <div className={styles.box}>
                <Link to='/' className={styles.logoLink}>
                    <img width={150} src={logo} />
                </Link>
            </div>
            <div className={styles.lightNavBarLinks} mode='horizontal' overflowedindicator={<MenuOutlined />} >

                <article className={styles.tab}>
                    <span className={styles.sectionLink}>Soluciones</span>
                    <div className={styles.dropDown}>
                        <div className={styles.triangle}></div>
                        <div className={styles.boxi}>
                            <Link to="/alto-rendimiento-deportivo">
                                <span className={styles.sectionLink}>Alto Rendimiento Deportivo
                                </span>
                            </Link>
                            <Link to="/alto-rendimiento-en-tu-organizacion">
                                <span className={styles.sectionLink}>Alto Rendimiento en tu Organización
                                </span>
                            </Link>
                            <Link to="/programas-personalizados">
                                <span className={styles.sectionLink}>Programas Indivuduales
                                </span>
                            </Link>
                        </div>
                    </div>
                </article>
                <Link to="/potentiia">
                    <span className={styles.sectionLink}>Nosotros</span>
                </Link>
                {/* <Link to="/contacto">
                    <span className={styles.sectionLink}>Contacto</span>
                </Link> */}
            </div>

            <div className={styles.navBarMobile}>
                <MenuOutlined
                    onClick={() => setShow(true)}
                    style={{ marginRight: "12px" }} />
                <div className={classNames(styles.menuDrop, {
                    [styles.menuDropInactive]: !showMenu
                })} >
                    <div>
                        <Link to='/' className={styles.logoColor}>
                            <img width={150} src={logoColor} />
                        </Link>
                        <CloseOutlined
                            onClick={hideMenu}
                            className={styles.closeIcon}
                            style={{ color: "#4B5563" }} />

                    </div>
                    <Link onClick={hideMenu} to="/potentiia">
                        <h3>Nosotros</h3>
                    </Link>
                    <Link onClick={hideMenu} to="/alto-rendimiento-deportivo">
                        <h3>Alto Rendimiento Deportivo</h3>
                    </Link>
                    <Link onClick={hideMenu} to="/alto-rendimiento-en-tu-organizacion">
                        <h3>Alto Rendimiento en tu Organización</h3>
                    </Link>
                    <Link onClick={hideMenu} to="/programas-personalizados">
                        <h3>Programas Individuales</h3>
                    </Link>
                    {/* <Link onClick={hideMenu} to="/contacto">
                        <h3>Contacto</h3>
                    </Link> */}
                </div>
            </div>
            {/* {!appData.loggedIn && <div className={styles.lightNavBarButton}>
                <Link to="/signup">
                    <span className={styles.sectionLink}>Registrate</span>
                </Link>
                <Link to="/login">
                    <Button type='secondary'>Iniciar sesión</Button>
                </Link>
            </div>}
            {appData.loggedIn && <div className={styles.lightNavBarButton}>
                <Link to="/" onClick={() => {
                    signOut();
                }}>
                    <span className={styles.sectionLink}>Cerrar Sesión</span>
                </Link></div>} */}
        </div >
    )

    return (
        <Header className={classNames(styles.navBarContainer, {
            [styles.light]: style === 'light'
        })}>

            {variant === 'light' ? whiteContent : blackContent}
        </Header>
    )
}

export default NavBar