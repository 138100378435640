import CourseContent from './pages/userProfile/courseContent/CourseContent'
import CoursesProfile from './pages/userProfile/courseProfile/CoursesProfile'
import NoticeOfPrivacy from './pages/noticeOfPrivacy/NoticeOfPrivacy'
import PersonalizedProgram from './pages/personalizedprogram/PersonalizedProgram'
import ContactForm from './pages/contact/ContactForm'
import FrecuentlyAskedQuestions from 'pages/faq/FrequentlyAskedQuestions'
import Home from 'pages/home/Home'
import SignIn from 'pages/login/SignIn'
import Signup from 'pages/login/Signup'
import Companies from 'pages/home/Companies'
import { Switch, Route, Redirect } from 'react-router-dom'
import Bfluency from 'pages/home/Bfluency'
import Athlete from 'pages/home/Athlete'
import { useContext } from 'react'
import { AppContext } from 'context/AppContext'
import Forgot from 'pages/login/Forgot'
import Programas from 'pages/home/Programas'

// Security middlewares
const RedirectLogin = (props) => { // review and update it
  const [appData] = useContext(AppContext)
  return appData.loggedIn ? <Redirect to={props.redirect} /> : <Redirect to="/signin" />
}

const IsLogged = (props) => {
  const [appData] = useContext(AppContext)
  return appData.loggedIn ? <Route path={props.path} {...props} /> : <Redirect to="/signin" />
}


const Routes = () => (
  <Switch>
    {/* Access routes */}
    <RedirectLogin path="/login" redirect="/perfil/mis-cursos" />
    <Route path="/signin" component={SignIn} />
    <Route path='/signup' component={Signup} />
    <Route path="/forgot" component={Forgot} />

    {/* Static routes */}
    <Route path='/politica-de-privacidad' component={NoticeOfPrivacy} />
    <Route path='/personaliza-tu-programa' component={PersonalizedProgram} />
    <Route path='/contacto' component={ContactForm} />
    <Route path='/preguntas-frecuentes' component={FrecuentlyAskedQuestions} />
    <Route path='/alto-rendimiento-deportivo' component={Companies} />
    <Route path='/potentiia' component={Bfluency} />
    <Route path='/alto-rendimiento-en-tu-organizacion' component={Athlete} />
    <Route path='/programas-personalizados' component={Programas} />

    {/* Redirects */}
    {/* <Redirect path="/perfil" to="/perfil/mis-cursos" /> */}

    {/* Dynamic routes */}
    <IsLogged path='/perfil/mis-cursos/curso1/contenido' component={CourseContent} />
    <IsLogged path='/perfil/mis-cursos' component={CoursesProfile} />
    <Route exact path='/' component={Home} />
  </Switch>
)

export default Routes
