//@flow
import * as React from 'react'
import classNames from 'classnames'
import privacyList from './internal/privacyList'
import styles from './NoticeOfPrivacy.module.css'
import PageFrame from 'primitives/layout/PageFrame'

type PrivacyListProps = {
  title?: string,
  description?: string,
  id?: number,
}

const PrivacyList = ({ title, description, id }: PrivacyListProps): React.Node => (
  <p>
    {' '}
    <span>{title}</span> {description}
  </p>
)

const NoticeOfPrivacy = (): React.Node => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <PageFrame>
      <div className={classNames(styles.NoticeOfPrivacyContainer)}>
        <div className={classNames(styles.NoticeOfPrivacyContent)}>
          <h2 className={classNames(styles.NoticeOfPrivacyTitle)}>Políticas de Privacidad</h2>
          <p>
            Aviso de privacidad El presente documento constituye el Aviso de privacidad de Privacidad para efectos de lo dispuesto en la Ley
            Federal de Protección de Datos Personales en Posesión de los Particulares (la “LFPDP”) y las disposiciones que emanan de ella o
            se relacionan con la misma.
          </p>
          <p>
            Este Aviso de privacidad aplica a la información personal recopilada sobre el Titular por bfluency en su carácter de
            Responsable. Para los efectos del presente Aviso de privacidad, los términos que no estén contemplados en el mismo tendrán el
            significado que se les atribuye en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDP). El
            presente Aviso de privacidad tiene por objeto establecer términos y condiciones por medio de los cuales bfluency recibirá y
            protegerá los Datos Personales del Titular, a efecto de proteger su privacidad ; utilizará los Datos Personales del Titular, y
            /o realizará en su caso las transferencias sus Datos Personales a terceros.
          </p>
          <div className={classNames(styles.PrivacyList)}>
            {privacyList.map((i) => (
              <PrivacyList key={i.id} {...i} />
            ))}
          </div>
        </div>
      </div>
    </PageFrame>
  )
}

export default NoticeOfPrivacy
