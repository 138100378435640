const mockData = [
  {
    course: 'Entrenamiento del equipo y los líderes',
    content: {
      modules: [
        {
          module: 'Módulo 1 - Una vida saludable',
          id: 1,
          lessons: [
            {
              name: 'Lección 1: Empieza tu día',
              avaliable: true,
              description: '¡Bienvenida al programa!',
              time: '1 hrs',
              progress: 100,
              id: 1,
            },
            {
              name: 'Lección 2: Empieza tu día',
              avaliable: true,
              description: '¡Bienvenida al programa!',
              time: '1 hrs',
              progress: 90,
              id: 2,
            },
            {
              name: 'Lección 3: Empieza tu día',
              description: '¡Bienvenida al programa!',
              time: '1 hrs',
              progress: 0,
              id: 3,
            },
            {
              name: 'Lección 4: Empieza tu día',
              description: '¡Bienvenida al programa!',
              time: '1 hrs',
              progress: 0,
              id: 4,
            },
          ],
        },
        {
          module: 'Módulo 2 - Una vida saludable',
          id: 2,
          lessons: [
            {
              name: 'Lección 1: Empieza tu día',
              description: '¡Bienvenida al programa!',
              time: '1 hrs',
              progress: 0,
              id: 1,
            },
            {
              name: 'Lección 2: Empieza tu día',
              description: '¡Bienvenida al programa!',
              time: '1 hrs',
              progress: 0,
              id: 2,
            },
            {
              name: 'Lección 3: Empieza tu día',
              description: '¡Bienvenida al programa!',
              time: '1 hrs',
              progress: 0,
              id: 3,
            },
            {
              name: 'Lección 4: Empieza tu día',
              description: '¡Bienvenida al programa!',
              time: '1 hrs',
              progress: 0,
              id: 4,
            },
            {
              name: 'Lección 5: Empieza tu día',
              description: '¡Bienvenida al programa!',
              time: '1 hrs',
              progress: 0,
              id: 5,
            },
            {
              name: 'Lección 6: Empieza tu día',
              description: '¡Bienvenida al programa!',
              time: '1 hrs',
              progress: 0,
              id: 6,
            },
          ],
        },
        {
          module: 'Módulo 3 - Ejercicio final',
          id: 3,
          lessons: [
            {
              name: 'Lección 1: Empieza tu día',
              description: '¡Bienvenida al programa!',
              time: '1 hrs',
              progress: 0,
              id: 1,
            },
          ],
        },
      ],
    },
  },
]

export default mockData
