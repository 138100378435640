import React from 'react';
import classNames from 'classnames';
import { Card as AntCard, Avatar } from 'antd'

import img from '../../assets/images/cardImage.png'
import ProgressBar from '../progressBar/ProgressBar'

import styles from './Card.module.css'

const { Meta } = AntCard;


type CardProps = {
    variant?: boolean,
    img?: string,
    title?: string,
    subtitle?: string,
    time?: number | string,
    progress?: number,
    changeFunction?: mixed,
}

const Card = ({
    variant, title, subtitle, time, progress, changeFunction
}: CardProps) => {
    
    return (
        <AntCard
            className = {classNames(styles.base, {
                [styles.locked] : !variant
            })}
            size = 'small'
            hoverable = {variant}
            cover = {<ProgressBar className = {classNames(styles.progressBar)} progress = {progress} />}
            onClick = {changeFunction}
        >
            <Meta
                className = {classNames(styles.content)}
                avatar = {
                    <div className = {classNames({
                        [styles.imgLocked] : !variant})}>
                    <Avatar size={83} src={img} />
                    </div>
                }
                title = {<h3 className = {classNames(styles.title)}>{title = 'Lección 1: Empieza tu día'}</h3>}
                description = { <div>
                    <p className = {classNames(styles.subtitle)}>{subtitle= '¡Bienvenida al programa!'} - <span>{time= '1 hrs'}</span></p>
                    </div>
                    
                }
            />
        </AntCard>
    )
}

export default Card