//@flow
import * as React from 'react'
import classNames from 'classnames'
import { Form, Input, Checkbox } from 'antd'
import Button from '../../primitives/buttons/Button'

import styles from './PersonalizedProgram.module.css'

type ProgramFormProps ={
  btnFunction: () => mixed
}
const ProgramForm = ({btnFunction}: ProgramFormProps):React.Node => {
  
    return (
       <>
        <p className={classNames(styles.personalizedProgramDescription)}>
            Completa la siguiente información sobre el programa que estas buscando para tu empresa, y nos pondremos en contacto contigo lo
            antes posible.
          </p>
          <Form layout='vertical'>
            <Form.Item name={['user', 'name']} label='Nombre'>
              <Input placeholder='Ingresa tu nombre' />
            </Form.Item>
            <Form.Item name={['user', 'email']} label='Email' rules={[{ type: 'email' }]}>
              <Input placeholder='ejemplo@bfluency.com' />
            </Form.Item>
            <Form.Item name={['user', 'tel']} label='Teléfono'>
              <Input placeholder='55 555 55 55' />
            </Form.Item>
            <Form.Item label='¿Por qué medio prefieres que te contactemos?' rules={[{ type: 'tel' }]}>
              <Checkbox.Group>
                <Checkbox style={{ color: '#262626' }} value='WhatsApp'>
                  WhatsApp
                </Checkbox>
                <Checkbox style={{ color: '#262626' }} value='Llamada '>
                  Llamada
                </Checkbox>
                <Checkbox style={{ color: '#262626' }} value='Email'>
                  Email
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <div className={classNames(styles.personalizedProgramBtn)}>
                <Button variantSize='small' onClick={btnFunction}>Siguiente</Button>
            </div>
          </Form>
        </>
    )
}
export default ProgramForm 