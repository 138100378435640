//@flow
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import classNames from 'classnames'
import styles from './Header.module.css'



const BreadcrumbNavigation = ():React.Node => {
const routes = [
  {
    path: 'index',
    breadcrumbName: 'Mis cursos',
  },
  {
    path: 'first',
    breadcrumbName: 'Curso 1',
  },
  {
    path: 'second',
    breadcrumbName: 'Ver curso',
  },
];
function itemRender(route, params, routes, paths) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
  );
}

    return (
        <Breadcrumb className={classNames(styles.breadcrumb)}
        separator='>' itemRender={itemRender} routes={routes}/>
    )
}

export default BreadcrumbNavigation