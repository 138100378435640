import * as React from 'react'
import Welcome from './Welcome'
import About from './About'
import Benefits from './Benefits'
import CommentsCarousel from './CommentsCarousel'
import ClientCarousel from './Client/ClientCarousel'
import PageFrame from 'primitives/layout/PageFrame'

const Home = (): React.Node => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <PageFrame>
      <Welcome />
      <About link={'/bfluency'}></About>
      <Benefits />
      <CommentsCarousel />
    </PageFrame>
  )
}
export default Home
