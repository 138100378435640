//@flow
import * as React from 'react'
import classNames from 'classnames'
import Button from 'primitives/buttons/Button'
import styles from './FrequentlyAskedQuestions.module.css'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import PageFrame from 'primitives/layout/PageFrame'

const FrecuentlyAskedQuestions = (): React.Node => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <PageFrame>
      <meta name='description' content=' Preguntas frecuentes' />
      <meta name='keywords' content='cursos, capacitación, couching, entrenamiento' />
      <title>Preguntas Frecuentes | bfluency</title>
      <div className={classNames(styles.questions)}>
        <Fade bottom>
          <h2 className={classNames(styles.title)}>Preguntas Frecuentes</h2>
          <p>A continuación, incluimos algunas de las preguntas más frecuentes que pueden ayudarte a resolver tus dudas.</p>
        </Fade>
        <br />
        <Fade bottom>
          <div className={classNames(styles.boxAsk)}>
            <div className={classNames(styles.ask)}>
              <h3>¿Los programas son solo adecuados si eres deportista?</h3>
              <p>
                No, los programas de bfluency nacen del conocimiento del alto rendimiento deportivo para el desarrollo de talento y de
                equipos, pero son adecuados para toda persona o equipo que se quiera desarrollar.
              </p>
            </div>
            <div className={classNames(styles.ask)}>
              <h3>¿Son programas de entrenamiento físico?</h3>
              <p>
                {' '}
                No, los programas están hechos para entrenar el mindset de personas y equipos. Son programas para entrenar ciertas
                capacidades humanas que tienen impacto en el desempeño. Y no solo laboral, también a nivel personal o incluso espiritual (si
                la persona se plantea objetivos en esta línea).
              </p>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className={classNames(styles.boxAsk)}>
            <div className={classNames(styles.ask)}>
              <h3>¿Qué resultados medibles se pueden obtener gracias a los programas?</h3>
              <p>
                Mayor estabilidad emocional en la persona, incremento en la capacidad de asumir y gestionar un cambio, mayor sensación de
                control sobre los elementos que se están trabajando y sobre la propia vida en general. Esto redunda en una mayor
                productividad y un menor impacto del estrés.
              </p>
            </div>
            <div className={classNames(styles.ask)}>
              <h3>¿Cómo sé que las herramientas que proponen vuestros programas impactan en la empresa?</h3>
              <p>
                {' '}
                Es algo que monitoreamos a través de cuestionarios, entrevistas y la propia experiencia.Además, el nivel de conocimiento de
                los cambios dependerá también de los datos que la empresa nos pueda proporcionar.Pero lo que sí sabemos es que en el 100% de
                los casos una formación con nosotros da como resultado, entre otros, una mejora en el clima laboral.
              </p>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className={classNames(styles.boxAsk)}>
            <div className={classNames(styles.ask)}>
              <h3>¿Cuánto tiempo hace falta para ver resultados?</h3>
              <p>
                Dependiendo del objetivo del programa, pero el entrenamiento en soft skills requiere tiempo para asentarse. Aunque haya
                cambios que se pueden ver en días, los hábitos durables requieren persistencia.
              </p>
            </div>
            <div className={classNames(styles.ask)}>
              <h3>¿Cualquiera puede apuntarse a vuestros programas?</h3>
              <p> Cualquier persona que quiera </p>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className={classNames(styles.boxAsk)}>
            <div className={classNames(styles.ask)}>
              <h3>¿Cuánto tiempo hace falta para ver resultados?</h3>
              <p>
                Dependiendo del objetivo del programa, pero el entrenamiento en soft skills requiere tiempo para asentarse. Aunque haya
                cambios que se pueden ver en días, los hábitos durables requieren persistencia.
              </p>
            </div>
            <div className={classNames(styles.ask)}>
              <h3>¿Cuáles son vuestros puntos diferenciadores de mercado?</h3>
              <p>
                {' '}
                Nosotros nacemos de nuestro conocimiento de la Psicología del Deporte dentro del Alto Rendimiento Deportivo, por lo que
                parte de las herramientas que aplicamos para el entrenamiento en habilidades blandas de los equipos y líderes proviere
                directamente de este entorno. No es solo una palabra para hacer marketing ;-)
              </p>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className={classNames(styles.callAction)}>
            <h3>¿Aún tienes alguna duda? </h3>
            <Link to='/contacto'>
              <Button variantSize='medium'>Contáctanos</Button>
            </Link>
          </div>
        </Fade>
      </div>
    </PageFrame>
  )
}

export default FrecuentlyAskedQuestions
