import * as React from "react";
import classNames from "classnames";
import { Button as AntButton } from 'antd'

import styles from './Button.module.css'

type ButtonProps = {|
    variant?: 'primary' | 'secondary',
        variantSize ?: 'small' | 'medium' | 'large',
        onClick: ButtonProps,
            children: React.Node | array < React.Node > | string,
    |}

const Button = ({ children, variant = 'primary', variantSize, onClick, ...props }: ButtonProps): React.Node => {
    return (
        <AntButton
            className={classNames({
                [styles.primary]: variant === 'primary',
                [styles.small]: variantSize === 'small',
                [styles.medium]: variantSize === 'medium',
                [styles.large]: variantSize === 'large',
            })}
            type={variant === 'secondary' ? 'text' : variant} onClick={onClick}
            {...props}
        >

            {children}
        </AntButton >
    )
}

export default Button