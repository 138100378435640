//@flow
import * as React from 'react'
import { useState } from 'react'
import classNames from 'classnames'
import ProgramForm from './ProgramForm'
import AditionalForm from './AditionalForm'

import styles from './PersonalizedProgram.module.css'
import PageFrame from 'primitives/layout/PageFrame'

type PersonalizedProgramProps = {
  preview?: boolean,
  widthImg?: string,
  heightImg?: string,
  colorCheckbox?: string,
}

const contact = 'https://images.pexels.com/photos/4498158/pexels-photo-4498158.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'

const PersonalizedProgram = ({ preview = false, widthImg = '100%', heightImg = '100%' }: PersonalizedProgramProps): React.Node => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const [page, setPage] = useState(false)

  const handleChange = () => {
    setPage(!page)
  }
  return (
    <PageFrame>
      <div className={classNames(styles.personalizedProgramContainer)}>
        <div className={classNames(styles.personalizedProgramContent)}>
          <h2 className={classNames(styles.personalizedProgramTitle)}>Personaliza tu programa.</h2>
          {page ? <AditionalForm btnFunction={handleChange} /> : <ProgramForm btnFunction={handleChange} />}
        </div>
        <div className={classNames(styles.personalizedProgramImgContainer)}>
          <div className={classNames(styles.personalizedProgramImg)} style={{ backgroundImage: `url("${contact}")` }}></div>
        </div>
      </div>
    </PageFrame>
  )
}

export default PersonalizedProgram
