//@flow
import * as React from 'react'
import classNames from 'classnames'
import Button from '../../primitives/buttons/Button'
import styles from './Welcome.module.css'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import ClientCarousel from './Client/ClientCarousel';

const image2 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fportada.svg?alt=media&token=8071405a-0be6-459f-9a5a-efa8ebe1a4ac"
const image3 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fpexels-fox-1595391.jpg?alt=media&token=2f33ab5f-549c-464c-8d30-cfb73f77656f"
const image4 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimg5.png?alt=media&token=a242d2e2-271a-4f5a-8c49-ee233df40591"
const image7 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimg3.svg?alt=media&token=655dd732-01df-41b5-8d9d-06d68c3d9695"
const image8 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/Curso1.svg?alt=media&token=787e1ecc-8c94-41d3-861c-307c13291809"
const image13 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/img3.svg?alt=media&token=88729780-94e6-4e61-a39b-5459af4496b7"
const image14 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/img4.svg?alt=media&token=ac748f49-4011-4652-85a9-91408482f61a"
const image15 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/lap.svg?alt=media&token=beeb6d03-b617-4e1e-b037-1b53ee8462fa"
const decoration = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fteamwork%20(1).svg?alt=media&token=4dbaa870-d129-4799-ac0f-77858a35e87c"
const fullimage = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fpotentia2.gif?alt=media&token=9fbee05c-c1e4-441c-be2a-79aeaba8c6b6"


type WelcomeProps = {
    preview?: boolean,
}

const Welcome = ({ preview = false }: WelcomeProps): React.Node => {

    return (
        <div>
            <div className={classNames(styles.welcomeContainer)}>
                <div className={classNames(styles.box)}>
                    <div>
                        <h1 className={classNames(styles.title, {
                            [styles.flipInX]: true,
                        })}>Desbloquea el  máximo potencial de tu equipo </h1>
                        <p className={classNames(styles.subtitleDescription)}>Desde Potentiia, utilizamos las metodologías de entrenamiento mental del  Alto Rendimiento Deportivo <br /> para que tu equipo y tú consigan sus objetivos.</p>
                        <Link to="/contacto">
                            <button className={classNames(styles.btnPrimary)}>Habla con nosotros</button>
                        </Link>
                        <Link to="/alto-rendimiento-deportivo">
                            <button className={classNames(styles.btnOutline)}> Ver programas</button>
                        </Link>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default Welcome