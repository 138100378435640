//@flow
import * as React from 'react'
import { Progress } from 'antd'

type ProgressBarProps = {
    className?: mixed,
    progress?: number,  
}

const ProgressBar = ({className,progress}:ProgressBarProps):React.Node => {
    const progressSettings = {
        strokeColor: {
            success: '#A7C86C',
        },
        trailColor:'#F5F5F5',
        percent: progress,   
    }

    return (
        <Progress className={className} {...progressSettings}/>
    )        
}

export default ProgressBar