// @flow
import React from 'react'
import Routes from './Routes'
import { AppContextProvider } from './context/AppContext'

import type { Node } from 'react'


function App(): Node {
  return (
    <AppContextProvider>
      <Routes />
    </AppContextProvider>
  )
}

export default App
