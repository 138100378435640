//@flow
import * as React from 'react'
import classNames from 'classnames'
import styles from './Athlete.module.css'
import { TeamOutlined, AlertOutlined, CommentOutlined, ReconciliationOutlined } from '@ant-design/icons'
import Fade from 'react-reveal/Fade';
import Button from '../../primitives/buttons/Button'
import { Link } from 'react-router-dom';

import PageFrame from 'primitives/layout/PageFrame';
// const img1 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimg-2.png?alt=media&token=29b2f310-0320-4338-b2b3-c6413471d1b1"
const icon2 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fhandshakes.svg?alt=media&token=370276da-49ee-46a2-a724-1c3df5e7f32b"
const icon3 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fbusiness-idea.svg?alt=media&token=3500c635-8deb-4e75-98d3-b1251154b890"
const icon = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Ftime-management%20(1).svg?alt=media&token=20d80d81-3803-4c47-82f8-767118a66d69"
const icon4 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fbusiness-dialog.svg?alt=media&token=d06e6de0-a699-4d96-804b-f268c449e692"
const img = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/lap.svg?alt=media&token=beeb6d03-b617-4e1e-b037-1b53ee8462fa"
const quote = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fquotation-marks%20(2).svg?alt=media&token=9315a1ef-3fee-49d8-80c9-d6e8ddcd99d5"
// const gif = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fteamwork%20(1).gif?alt=media&token=6003746c-0ccc-4b38-acc4-4da0f3cf2068"


const img1 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2FEdenred-White-RGB.png?alt=media&token=61ecc44d-9f53-414e-b105-2c0a62ee249b"
const img2 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2FLogo_Chedraui.png?alt=media&token=3430f3d8-d0f1-4dd4-b4b4-aff99d1fb790"
const img3 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2FLogo_Web_white.1.png?alt=media&token=cfe50e7d-73bd-4a0a-ae4b-d755656d2fc3"
const img4 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2FLogo_red_universidades1-feb2022.png?alt=media&token=21f039a4-8976-47ba-be3b-43c598596fb9"
const img5 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Fcfe.png?alt=media&token=3b88d79f-4900-433f-a14f-a2e9e1ec10cc"
const img6 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2FLogo-BBVA.png?alt=media&token=9de52a68-0fff-494b-8731-83f50431e997"
const img7 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Fpat-logo.png?alt=media&token=cc3d90fb-4dc5-457f-bd3e-61e333c86626"
const img8 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Fescudo-de-armas.png?alt=media&token=2487b30a-4b8f-48c9-884e-2a9a8b42386d"
const img9 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Fescudo-de-armas.png?alt=media&token=9f38c952-55fd-4351-9337-31a61fd89526"
const img10 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Ftecnologico-de-monterrey-white.png?alt=media&token=77a33d1e-0ad5-4395-a2de-0705f538d129"
const img11 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Flogo4_metlife.png?alt=media&token=03bdb43f-4cc1-444a-8891-20a5bd3f2778"
const check = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2FCheck%20Mark.gif?alt=media&token=102f797e-1f3d-4a4d-9b2a-8bf2c9eaa799"
const gif = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fteamwork%20(1).gif?alt=media&token=6003746c-0ccc-4b38-acc4-4da0f3cf2068"

const Athlete = (): React.Node => {
    React.useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <PageFrame>
            <meta property="og:title" content="Alto rendimiento en tu organización | Potentiia " />
            <meta property="og:description"
                content="Utilizamos estrategias probadas de alto rendimiento deportivo y militar para alcanzar metas empresariales." />

            <section className={classNames(styles.companiesContainer)}>
                <div className={classNames(styles.header)}>
                    <div className={classNames(styles.companiesInfo)}>
                        <Fade bottom>
                            <h2 className={classNames(styles.titleDescription)}>NO FORMES A TU EQUIPO, ¡ENTRÉNALO!  </h2>
                            <p >En Potentiia, utilizamos estrategias probadas de alto rendimiento deportivo y militar para alcanzar metas empresariales, creando una dinámica de trabajo en equipo que fortalece el liderazgo y la gestión emocional.
                            </p>
                            <Link to="/contacto">
                                <Button size="medium">Habla con nosotros</Button>
                            </Link>
                        </Fade>
                    </div>
                </div>
                {/* <div className={classNames(styles.aboutContainer)}>
                    <Fade bottom>
                        <div className={classNames(styles.aboutContent)}>
                            <h2 className={classNames(styles.title)}>Más de 12 años de experiencia acompañando a nuestros clientes
                            </h2>
                            <div className={classNames(styles.box)}>
                                <img src={img1} />
                                <img src={img2} />
                                <img src={img3} />
                                <img src={img4} />
                                <img src={img5} />
                                <img src={img6} />
                                <img src={img7} />

                                <img src={img9} />
                                <img src={img10} />
                                <img src={img11} />
                            </div>
                        </div>
                    </Fade >
                </div > */}

                <section className={classNames(styles.services)}>
                    <Fade bottom>
                        <h2 className={classNames(styles.head)}>
                            ¿Qué características tiene un equipo de Alto Rendimiento?
                        </h2>
                        <div className={classNames(styles.cardsBox)}>
                            <div className={classNames(styles.cardItem)} style={{ backgroundImage: 'url(https://images.pexels.com/photos/1552617/pexels-photo-1552617.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)' }}>
                                <div className={classNames(styles.cover)}>
                                    <div>
                                        <h3>Un objetivo común, un propósito</h3>
                                        <p className={classNames(styles.description)}>Cada miembro se mueve hacia la meta, impulsado por sus propósitos personales y por su compromiso con el éxito de la compañía.</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(styles.cardItem)} style={{ backgroundImage: 'url(https://images.pexels.com/photos/7578704/pexels-photo-7578704.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)' }}>
                                <div className={classNames(styles.cover)} >
                                    <div>
                                        <h3>Confianza</h3>
                                        <p className={classNames(styles.description)}>cada persona confía en sus capacidades y en las de los demás.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(styles.cardItem)} style={{ backgroundImage: 'url(https://images.pexels.com/photos/7640471/pexels-photo-7640471.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)' }}>
                                <div className={classNames(styles.cover)} >
                                    <div>
                                        <h3>Gestión de conflictos y de estrés</h3>
                                        <p className={classNames(styles.description)}>Se promueve un entorno psicológicamente seguro donde cada miembro puede ejercer su liderazgo y confrontar los temas que nos impulsan a la meta.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(styles.cardItem)} style={{ backgroundImage: 'url(https://images.pexels.com/photos/3184431/pexels-photo-3184431.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)' }}>
                                <div className={classNames(styles.cover)}>
                                    <div>
                                        <h3>Responsabilidades y compromisos</h3>
                                        <p className={classNames(styles.description)}>Cada persona entiende cuál es su rol y tiene la capacidad de tomar decisiones de manera autónoma, asumiendo las responsabilidades y aprendizajes de las mismas.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(styles.cardItem)} style={{ backgroundImage: 'url(https://images.pexels.com/photos/2681319/pexels-photo-2681319.jpeg?auto=compress&cs=tinysrgb&w=1600)' }}>
                                <div className={classNames(styles.cover)}>
                                    <div>
                                        <h3>Capacidad constante de aprendizaje</h3>
                                        <p className={classNames(styles.description)}>Vemos los errores como oportunidades para crecer y mejorar.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Fade>
                </section>
                <section className={classNames(styles.cta)}>
                    <Fade bottom>
                        <h2 className={classNames(styles.head)}>
                            ¿Programas Personalizados para el Éxito Empresarial?
                        </h2>
                        <p className={classNames(styles.text)}>Para cambiar, primero hay que entender que necesitas cambiar. Estos son algunos de nuestros programas personalizados</p>
                        <div className={classNames(styles.cardBox)}>
                            <div className={classNames(styles.card)}>
                                <div className={classNames(styles.img)}>
                                    <img className={classNames(styles.circle)} src="https://images.pexels.com/photos/3321789/pexels-photo-3321789.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                                </div>
                                <h3>Conferencias</h3>
                                <p>Para cambiar, primero hay que entender que necesitas cambiar. Las conferencias pueden ser un buen inicio en este cambio de mentalidad.</p>
                                <p> <strong>¡Pregunta por nuestra oferta de temáticas!</strong></p>
                            </div>
                            <div className={classNames(styles.card)}>
                                <div className={classNames(styles.img)}>
                                    <img className={classNames(styles.circle)} src="https://images.pexels.com/photos/3719037/pexels-photo-3719037.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                                </div>
                                <h3>Talleres</h3>
                                <p>Dar un primer paso es importante. Los talleres te pueden <strong> ayudar a trabajar alguno de los aspectos con tu equipo y probar alguna de las herramientas que el Alto Rendimiento</strong> puede ofrecerle a tu compañía.</p>
                            </div>
                            <div className={classNames(styles.card)}>
                                <div className={classNames(styles.img)}>
                                    <img className={classNames(styles.circle)} src="https://images.pexels.com/photos/421160/pexels-photo-421160.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                                </div>
                                <h3>Entrenamientos</h3>
                                <p>Nuestros planes se adaptan en <strong> tiempo y formato a tus necesidades</strong> yendo desde los 3 meses hasta los 9 y usando diferentes herramientas para lograr las metas, desde talleres, hasta coaching grupal o individual.</p>
                            </div>
                            <div className={classNames(styles.card)}>
                                <div className={classNames(styles.img)}>
                                    <img className={classNames(styles.circle)} src="https://images.pexels.com/photos/3756042/pexels-photo-3756042.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                                </div>
                                <h3>Coaching Individual
                                </h3>
                                <p>Para miembros del equipo que están buscando mejorar.<br /> Nuestros programas de coaching <strong> trabajan el desarrollo de una forma integral partiendo del conocimiento</strong> y tratando a la persona como un atleta de Alto Rendimiento.</p>
                            </div>
                            <div className={classNames(styles.card)}>
                                <div className={classNames(styles.img)}>
                                    <img className={classNames(styles.circle)} src="https://images.pexels.com/photos/4427813/pexels-photo-4427813.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                                </div>
                                <h3>Programas para Líderes
                                </h3>
                                <p>Programa pensado para el <strong> desarrollo del liderazgo con 8 sesiones individuales de coaching y 10 sesiones en equipo</strong>. <br />El objetivo es apoyar en el desarrollo a los equipos directivos que están liderando los cambios en las compañías.</p>

                            </div>
                        </div>
                        <Link to="/contacto">
                            <Button size="medium">Solicitar más información</Button>
                        </Link>
                    </Fade>
                </section>

            </section >
        </PageFrame >
    )
}

export default Athlete