//@flow 
import * as React from 'react'
import classNames from 'classnames'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import Button from '../../primitives/buttons/Button'

import {
    SmileOutlined, BranchesOutlined, RocketOutlined,
    TeamOutlined, CommentOutlined, ClockCircleOutlined
} from '@ant-design/icons';


import styles from './Benefits.module.css'

const img = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fimg-4.png?alt=media&token=7e599022-f498-4645-a85e-b0a344d17747"

const Benefits = (): React.Node => {
    return (
        <div className={classNames(styles.benefitsContainer)}>
            <Fade bottom>
                <img src={img} />
            </Fade>
            <Fade bottom>
                <div>
                    <h2 className={classNames(styles.titleDescription)}>
                        Desarrollo de equipos de Alto Rendimiento Deportivo
                    </h2>
                    <p className={classNames(styles.text)}>
                        La <strong> preparación mental</strong>  de un equipo de alto rendimiento deportivo y su entorno es una de las claves del éxito para conseguir las metas.
                    </p>
                    <p className={classNames(styles.text)}>
                        Cada una de nuestras sesiones está pensada para dar al deportista y a su entorno <strong> herramientas prácticas para construir a largo plazo pero empezar a obtener beneficios en el corto plazo.
                        </strong> </p>
                    <p className={classNames(styles.text)}>
                        Tenemos <strong>diferentes formatos </strong> para diferentes necesidades. Asesorate con nosotros y en 20 minutos resuelve cómo vas a dar el siguiente paso.
                    </p>
                    <br />
                    <Link to="/contacto">
                        <Button variantSize='medium'>Quiero una asesoría de 20 minutos</Button>
                    </Link>
                </div>
            </Fade>
        </div >
    )
}

export default Benefits