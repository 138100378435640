//@flow
import * as React from 'react'
import classNames from 'classnames'
import Button from '../../primitives/buttons/Button'
import styles from './Companies.module.css'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom'
import PageFrame from 'primitives/layout/PageFrame';
import passion from "../../assets/images/passion.svg"
import control from "../../assets/images/control.svg"
import trust from "../../assets/images/trust.svg"
import cultivate from "../../assets/images/cultivate.svg"
import team from "../../assets/images/team.svg"

import img21 from "../../assets/images/logos/nascar.png"
import img22 from "../../assets/images/logos/pachuca.png"
import img24 from "../../assets/images/logos/pga.png"
import img31 from "../../assets/images/logos/rayados.png"



type CompaniesProps = {
    link: string,
}

const Companies = ({ link }: CompaniesProps): React.Node => {
    React.useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <PageFrame>
            <meta property="og:title" content="Alto rendimiento Deportivo | Potentiia " />
            <meta property="og:description"
                content="Aplicamos las herramientas usadas en el ámbito del alto rendimiento deportivo y militar para la consecución de metas." />

            <section className={classNames(styles.companiesContainer)}>
                <div className={classNames(styles.header)}>
                    <div className={classNames(styles.companiesInfo)}>
                        <Fade bottom>
                            <h2 className={classNames(styles.titleDescription)}>El talento solo no predice éxito: súmale mentalidad de Alto Rendimiento    </h2>
                            <p >Potentiia trae todas aquellas herramientas usadas en el ámbito del alto rendimiento deportivo y militar para la consecución de metas, sabiendo que la base sólida son los motores de los miembros del equipo.</p>
                            <Link to="/contacto">
                                <Button size="medium">Habla con nosotros</Button>
                            </Link>
                        </Fade>
                    </div>
                </div>
                {/* <div className={classNames(styles.aboutContainer)}>
                    <Fade bottom>
                        <div className={classNames(styles.aboutContent)}>
                            <h2 className={classNames(styles.title)}>Más de 12 años de experiencia acompañando a nuestros clientes
                            </h2>
                            <div className={classNames(styles.box)}>
                                <img src={img21} />
                                <img src={img22} />
                                <img src={img24} />
                                <img src={img31} />
                            </div>
                        </div>
                    </Fade >
                </div > */}
                <div className={classNames(styles.stepsContainer)}>
                    <Fade bottom>
                        <div className={classNames(styles.steps)}>
                            <h2 className={classNames(styles.head)}>
                                Desarrollo de equipos de Alto Rendimiento Deportivo
                            </h2>
                            <span className={classNames(styles.ban)} > <strong>ALTO RENDIMIENTO: UN ESTILO DE VIDA PARA LOGRAR EL ÉXITO</strong></span>
                            <br />    <br />
                            <p className={classNames(styles.text)}>
                                En Potentiia, creemos que el alto rendimiento no se limita a los atletas profesionales. Cada individuo en tu institución puede adoptar el estilo de vida de alto rendimiento para alcanzar objetivos.
                            </p>
                            <p className={classNames(styles.text)} style={{ fontFamily: "LarseitBold" }}>Pero... ¿Qué significa un mindset de Alto Rendimiento en mi institución?</p>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <img src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fimg-7.png?alt=media&token=bd110f71-96b5-4982-8d12-49131bd14cd0" />
                    </Fade>
                </div >
                <div className={classNames(styles.ideas)}>

                    <div className={classNames(styles.ideaItem)}>
                        <img src={passion} />
                        <p>Significa que cada persona que la componen sea parte activa del plan que nos va a llevar a la meta, alineando la pasión y la misión.</p>
                    </div>
                    <div className={classNames(styles.ideaItem)}>
                        <img src={control} />
                        <p>Significa equipar a cada miembro del equipo con herramientas para alcanzar su mejor versión
                        </p>
                    </div>
                    <div className={classNames(styles.ideaItem)}>
                        <img src={trust} />
                        <p>Significa fomentar la confianza y apoyo mutuo
                        </p>
                    </div>
                    <div className={classNames(styles.ideaItem)}>
                        <img src={cultivate} />
                        <p>Significa cultivar la capacidad de aprender de los fracasos
                        </p>
                    </div>
                    <div className={classNames(styles.ideaItem)}>
                        <img src={team} />
                        <p>Significa fortalecer la visión de todo el equipo.
                        </p>
                    </div>

                </div>
                <section className={classNames(styles.services)}>
                    <Fade bottom>
                        <h2 className={classNames(styles.head)}>
                            Nuestras soluciones para lograr el cambio
                        </h2>
                        <div className={classNames(styles.cardsBox)}>
                            <div className={classNames(styles.cardItem)} style={{ backgroundImage: 'url(https://images.pexels.com/photos/2977547/pexels-photo-2977547.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)' }}>
                                <div className={classNames(styles.cover)}>
                                    <div>
                                        <h3>Onboarding a la institución</h3>

                                        <p className={classNames(styles.description)}>Una rápida adaptación asegura el rendimiento del talento y minimiza la posibilidad de pérdida.</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(styles.cardItem)} style={{ backgroundImage: 'url(https://images.pexels.com/photos/3799235/pexels-photo-3799235.jpeg?auto=compress&cs=tinysrgb&w=1600)' }}>
                                <div className={classNames(styles.cover)}>
                                    <div>
                                        <h3>Entrenamiento mental en lesión deportiva</h3>

                                        <p className={classNames(styles.description)}>Apoyamos en el entrenamiento mental durante las lesiones deportivas para acelerar la recuperación en un 20%.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(styles.cardItem)} style={{ backgroundImage: 'url(https://images.pexels.com/photos/4944958/pexels-photo-4944958.jpeg?auto=compress&cs=tinysrgb&w=1600)' }}>
                                <div className={classNames(styles.cover)}>
                                    <div >
                                        <h3>Coaching individual</h3>
                                        <p className={classNames(styles.description)}>
                                            Desarrollamos el potencial de cada atleta, miembro del cuerpo técnico o personal de la institución dentro del modelo de Alto Rendimiento Deportivo. Descubre más en nuestra sección de Programas Individuales.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(styles.cardItem)} style={{ backgroundImage: 'url(https://images.pexels.com/photos/3321796/pexels-photo-3321796.jpeg?auto=compress&cs=tinysrgb&w=1600)' }}>
                                <div className={classNames(styles.cover)}>
                                    <div>
                                        <h3>Talleres</h3>

                                        <p className={classNames(styles.description)}>Creamos conciencia sobre temas relevantes para el desarrollo del talento deportivo.</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames(styles.cardItem)} style={{ backgroundImage: 'url(https://images.pexels.com/photos/6456291/pexels-photo-6456291.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)' }}>
                                <div className={classNames(styles.cover)}>
                                    <div>
                                        <h3>Retirada deportiva</h3>

                                        <p className={classNames(styles.description)}>Apoyamos a los atletas en su transición post-deportiva, elemento clave para su rendimiento en fases finales y bienestar personal.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Fade>
                </section>
                <section className={classNames(styles.cta)}>
                    <Fade bottom>
                        <h2 className={classNames(styles.head)}>
                            ¿Estás listo para adoptar el estilo de vida de Alto Rendimiento Deportivo?

                        </h2>
                        <p className={classNames(styles.text)}>Contáctanos y hablemos sobre qué necesita tu institución para abrazar el Alto Rendimiento Deportivo como estilo de vida. Te ayudaremos a crear el mindset correcto, fortalecer el entrenamiento mental, y promover el desarrollo de talento para que cada miembro de tu equipo pueda alcanzar su mejor versión.</p>
                        <Link to="/contacto">
                            <Button size="medium">Contáctanos</Button>
                        </Link>
                    </Fade>
                </section>
                {/* <div className={classNames(styles.companiesSteps)}>

                    <Fade bottom>
                        <div className={classNames(styles.card)}>
                        
                            <h3 className={classNames(styles.stepsName)}>Evaluación Inicial</h3>
                            <p>Se evalúa tanto al equipo completo con el que se va a trabajar y se identifican los líderes funcionales y emocionales del mismo.</p>
                            <p>Con ello se seleccionan las variables a trabajar durante el entrenamiento y su forma de medición para poder evaluar el avance.</p>
                            <img className={classNames(styles.arrow)} src={arrow} />
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className={classNames(styles.card)}>
                            <h3 className={classNames(styles.stepsName)}>Planeación del entrenamiento</h3>
                            <p>Para el equipo: se trabaja con todos los miembros del equipo con el objetivo de asentar las bases de funcionamiento del equipo de alto rendimiento.</p>
                            <p>Para los líderes: sesiones individuales o grupales con los líderes con el objetivo de formarlos para poder dirigir al equipo.</p>
                            <img className={classNames(styles.arrow)} src={arrow} />

                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className={classNames(styles.card)}>
                            <h3 className={classNames(styles.stepsName)}>Entrenamiento del equipo y los líderes</h3>
                            <p>Durante la duración del entrenamiento las sesiones son solo una pequeña parte del mismo, ya que después de cada una de ellas los equipos y líderes tendrán que realizar ejercicios aplicados a su día a día con el apoyo del equipo de bfluency.</p>
                            <img className={classNames(styles.arrow)} src={arrow} />

                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className={classNames(styles.card)}>
                            <h3 className={classNames(styles.stepsName)}>Evaluación final</h3>
                            <p>Se evalúan los aprendizajes, junto con los cambios y repercusiones que estos tienen en la organización con las métricas acordadas.</p>

                        </div>
                    </Fade>
                </div> */}

            </section>
        </PageFrame >
    )
}




export default Companies
