import * as React from 'react'
import axios from 'axios'
import PageFrame from 'primitives/layout/PageFrame'
import Button from 'primitives/buttons/Button'
import {
    Card,
} from 'antd'
import classNames from 'classnames'
import { Form, Input, notification } from 'antd';

import styles from './Login.module.css'
import { Link } from 'react-router-dom'
import FormRules from 'utils/rules'


const Forgot = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false)

    const onFinish = values => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_SERVER}/auth/recovery`, values)
            .then(({ data }) => {
                setLoading(false)
                if (data) {
                    notification.success({
                        message: 'Email enviado con éxito',
                        description:
                            'Revisa tu bandeja de entrada',
                        duration: 8
                    });
                }
            })
            .catch(err => {
                console.error(err)
                notification.error({
                    message: 'No se pudo enviar el email',
                    description:
                        'Verifica email',
                    duration: 0
                });
                setLoading(false)
            })
    }

    return (<PageFrame>
        <div className={styles.container}>
            <Card className={classNames(styles.card)}>
                <p className={classNames(styles.title)}>¿Olvidaste tu contraseña?</p>
                <span className={classNames(styles.subtitle)}>
                    Ingresa tu email, y te enviaremos un correo electrónico con una contraseña provisional para que puedas acceder a tu cuenta y cambiarla.
                </span>
                <Form
                    style={{ marginTop: 32 }}
                    requiredMark={false}
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                // autoComplete="off"
                >
                    <Form.Item
                        style={{ textAlign: 'left' }}
                        name="email"
                        label="Email"
                        rules={[
                            FormRules.email
                        ]}
                    >
                        <Input placeholder="ejemplo@bfluency.com" />
                    </Form.Item>
                    <div className={styles.buttonContainer}>
                        <Button
                            loading={loading}
                            htmlType="submit" variantSize="large">
                            Enviar
                        </Button>
                    </div>
                    <p>¿Ya tienes cuenta? <Link to="/login">Inicia sesión</Link></p>
                </Form>

            </Card>
        </div></PageFrame>
    )
}

export default Forgot