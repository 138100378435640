import * as React from 'react'
import axios from 'axios'
import PageFrame from 'primitives/layout/PageFrame'
import Button from 'primitives/buttons/Button'
import {
    Card,
} from 'antd'
import classNames from 'classnames'
import googleLogo from '../../assets/images/logos/google-logo.png'
import { LinkedinOutlined } from '@ant-design/icons'
import { Form, Input, message, notification } from 'antd';

import styles from './Login.module.css'
import { Link, useHistory } from 'react-router-dom'
import FormRules from 'utils/rules'
import { AppContext } from 'context/AppContext'
import { loginWithLinkedin } from 'utils/firebase'


const Divider = () => (
    <div className={styles.divider}></div>
)

const Signin = ({ location }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false)
    const [, setAppContextData] = React.useContext(AppContext)
    const history = useHistory()

    React.useEffect(() => {
        const code = new URLSearchParams(location.search).get("code")
        console.log(code)
        if (code) {
            setLoading(true)
            axios.post(`${process.env.REACT_APP_SERVER}/auth/linkedin/token`, { access_token: code })
                .then(result => {
                    setLoading(false)
                    if (result.data) {
                        message.success(`Bienvenid@ ${result.data?.user?.displayName}`)
                        setAppContextData(result.data)
                        history.push('/perfil/mis-cursos')
                    }


                })
                .catch(err => {
                    console.error(err)
                    notification.error({
                        message: 'No se pudo iniciar sesión en esta red social',
                        description:
                            'Intenta con otro método',
                        duration: 0
                    });
                    setLoading(false)
                })
        }
    }, [])

    const onFinish = values => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_SERVER}/auth/signin`, values)
            .then(({ data }) => {
                setLoading(false)
                if (data) {
                    message.success('Bienvenid@ de nuevo')
                    setAppContextData(data)
                    history.push('/perfil/mis-cursos')
                }
            })
            .catch(err => {
                console.error(err)
                notification.error({
                    message: 'No se pudo iniciar sesión',
                    description:
                        'Verifica tu usuario y contraseña porfavor.',
                    duration: 0
                });
                setLoading(false)
            })
    }

    const linkedinLogin = () => {
        // window.open(`${process.env.REACT_APP_CLIENT_TOKEN}`, "", "width=400,height=600,top=200,left=500")
        window.location.href = (`${process.env.REACT_APP_CLIENT_TOKEN}`)
    }

    return <PageFrame>
        <div className={styles.container}>
            <Card className={classNames(styles.card)}>
                <p className={classNames(styles.title)}>Inicia Sesión</p>
                <span className={classNames(styles.subtitle)}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, turpis magna euismod turpis sed. Vitae odio est aliquam.
                </span>
                <div className={styles.buttons}>
                    <button disabled={loading} className={styles.google}>
                        <img width={20} src={googleLogo} alt="google-logo" />{" "}<span>Google</span>
                    </button>
                    <button
                        disabled={loading}
                        onClick={linkedinLogin}
                        className={styles.in}>
                        <LinkedinOutlined />{" "}
                        <span> Linkedin</span>
                    </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}><Divider /> <span className={styles.subtitle}>o</span> <Divider /></div>
                <Form
                    requiredMark={false}
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                // autoComplete="off"
                >
                    <Form.Item
                        style={{ textAlign: 'left' }}
                        name="email"
                        label="Email"
                        rules={[
                            FormRules.email
                        ]}
                    >
                        <Input placeholder="ejemplo@bfluency.com" />
                    </Form.Item>
                    <Form.Item
                        style={{ textAlign: 'left' }}
                        name="password"
                        label="Contraseña"
                        rules={[
                            FormRules.password,
                            FormRules.passwordPattern,
                        ]}
                    >
                        <Input.Password placeholder="********" />
                    </Form.Item>
                    <Link to="/forgot">
                        Olvide mi contraseña
                    </Link>
                    <br />
                    <div className={styles.buttonContainer}>
                        <Button
                            loading={loading}
                            htmlType="submit" variantSize="large">
                            Iniciar Sesión
                        </Button>
                    </div>
                    <p>¿No tienes cuenta? <Link to="/Signup">Registrate</Link></p>
                </Form>

            </Card>
        </div>
    </PageFrame >
}
export default Signin
