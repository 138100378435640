//@flow
import * as React from 'react'
import classNames from 'classnames'
import { Avatar } from 'antd'
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, WhatsAppOutlined } from '@ant-design/icons'
import footerLogo from '../../assets/images/logo_footer.png'
import { Link } from 'react-router-dom'
import Button from '../../primitives/buttons/Button'

import styles from './Footer.module.css'
import logo from "../../assets/images/potentiia.png"

const fb = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2FFacebook.svg?alt=media&token=3b9655a4-80a1-412b-b8ed-3f551c034802"
const tweet = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2FTwitter.png?alt=media&token=f602e52e-b2e7-480e-a910-e58ddd698bcb"
const ln = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2FLinkedin.png?alt=media&token=ebfeaed5-e4cf-4815-b561-533520562ebd"

const insta = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Finsta.svg?alt=media&token=01ca76b0-59c6-4fb1-90df-c80021f3307f"
type FooterProps = {
    blogLink?: string,
}

const Footer = ({ blogLink }: FooterProps): React.Node => {
    return (
        <section className={classNames(styles.footerContainer)}>
            <div className={classNames(styles.footerInfo)}>
                <div className={classNames(styles.newsletter)}>
                    <img className={classNames(styles.logo)} src={logo} />
                    {/* <p>Suscríbete y mantente al tanto de lo que pasa en Potentiia</p>
                    <div className={classNames(styles.form)}>
                        <input placeholder='Ingresa tu email' />
                        <button className={classNames(styles.btnSecondary)}>Enviar</button>
                    </div> */}
                </div>
                <div className={classNames(styles.sections)}>
                    <p style={{ color: "#D7D7D7", fontWeight: "bold" }}>Sobre Potentiia</p>

                    <Link to="/potentiia">
                        <p>Nosotros</p>
                    </Link>
                    <Link to="/alto-rendimiento-deportivo">
                        <p>Alto Rendimiento Deportivo</p>
                    </Link>
                    <Link to="/alto-rendimiento-en-tu-organizacion">
                        <p>Alto Rendimiento en tu Organización</p>
                    </Link>


                </div>
                {/* <div className={classNames(styles.media)}>
                    <p style={{ color: "#D7D7D7", fontWeight: "bold" }}>Síguenos</p>
                    <div className={classNames(styles.redes)}>
                        <Link>
                            <img src={fb} />
                        </Link>
                        <Link>
                            <img src={tweet} />
                        </Link>
                        <Link>
                            <img src={ln} />
                        </Link>
                    </div>
                </div> */}
            </div>
            <div className={classNames(styles.disclaimer)}>
                <span>© Copyright 2023. Todos los derechos reservados por Potentiia</span>
                <div className={classNames(styles.terms)}>
                    <Link to="/politica-de-privacidad">
                        <span className={classNames(styles.tab)}>Aviso de Privacidad</span>
                    </Link>
                    {/* <span className={classNames(styles.tab)}>Ayuda</span> */}

                </div>
            </div>

        </section >
    )
}

export default Footer