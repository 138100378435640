//@flow
import * as React from 'react'
import classNames from 'classnames'
import { Divider } from 'antd'
import SideBar from 'primitives/sideBar/SideBar'
import Card from 'primitives/cards/Card'

import mockData from './mockData'

import styles from './CourseContent.module.css'
import Header from '../../../primitives/header/Header'

type CourseContentProps = {
  courseImg?: string,
  courseName?: string,
  modules?: string,
}

const CourseContent = ({ courseImg, courseName, modules }: CourseContentProps): React.Node => {
  courseName = 'Entrenamiento del equipo y los líderes'
  courseImg =
    'https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/19621088_1334780026617382_7823503395334101987_o.jpeg?alt=media&token=50d41518-72e7-4dc5-87ee-e686e55683bd'

  return (
    <>
      <Header variant='secondary' courseImg={courseImg} />
      <SideBar />
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.content)}>
          <h2>{mockData.map((i) => i.course)}</h2>
          <Divider className={classNames(styles.divider)} />
          {mockData.map((i) =>
            i.content.modules.map((i) => (
              <div key={i.id}>
                <h3 className={classNames(styles.moduleTitle)}> {i.module} </h3>
                <div className={classNames(styles.cardContainer)}>
                  <Card variant='true' progress={100} />
                  <Card variant='true' progress={40} />
                  <Card />
                  <Card />
                </div>
              </div>
            )),
          )}
        </div>
      </div>
    </>
  )
}

export default CourseContent
