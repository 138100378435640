//@flow
import * as React from 'react'
import classNames from 'classnames'
import { ArrowRightOutlined } from '@ant-design/icons';
import styles from './About.module.css'
import Fade from 'react-reveal/Fade';
import img1 from "../../assets/images/logos/aaa.png"
import img2 from "../../assets/images/logos/bancomer.png"
import img3 from "../../assets/images/logos/cfe.png"
import img4 from "../../assets/images/logos/chedraui.png"
import img5 from "../../assets/images/logos/comite.png"
import img6 from "../../assets/images/logos/hdi.png"
import img7 from "../../assets/images/logos/doka.png"
import img8 from "../../assets/images/logos/edenred.png"
import img9 from "../../assets/images/logos/factor.png"
import img10 from "../../assets/images/logos/logoqiip.png"
import img11 from "../../assets/images/logos/gmexico.png"
import img12 from "../../assets/images/logos/google-logo.png"
import img13 from "../../assets/images/logos/gpx.png"
import img14 from "../../assets/images/logos/in.png"
import img15 from "../../assets/images/logos/intercam.png"
import img16 from "../../assets/images/logos/itp.png"
import img17 from "../../assets/images/logos/cruyff.png"
import img18 from "../../assets/images/logos/lasalle.png"
import img19 from "../../assets/images/logos/martifer.png"
import img20 from "../../assets/images/logos/metlife.png"
import img21 from "../../assets/images/logos/nascar.png"
import img22 from "../../assets/images/logos/pachuca.png"
import img23 from "../../assets/images/logos/patprimo.png"
import img24 from "../../assets/images/logos/pga.png"
import img25 from "../../assets/images/logos/rh.png"
import img26 from "../../assets/images/logos/rvm.png"
import img27 from "../../assets/images/logos/tribunal.png"
import img28 from "../../assets/images/logos/sura.png"
import img29 from "../../assets/images/logos/swiss.png"
import img30 from "../../assets/images/logos/timken.png"
import img31 from "../../assets/images/logos/toro.png"
import img32 from "../../assets/images/logos/rayados.png"

// const img1 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2FEdenred-White-RGB.png?alt=media&token=61ecc44d-9f53-414e-b105-2c0a62ee249b"
// const img2 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2FLogo_Chedraui.png?alt=media&token=3430f3d8-d0f1-4dd4-b4b4-aff99d1fb790"
// const img3 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2FLogo_Web_white.1.png?alt=media&token=cfe50e7d-73bd-4a0a-ae4b-d755656d2fc3"
// const img4 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2FLogo_red_universidades1-feb2022.png?alt=media&token=21f039a4-8976-47ba-be3b-43c598596fb9"
// const img5 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Fcfe.png?alt=media&token=3b88d79f-4900-433f-a14f-a2e9e1ec10cc"
// const img6 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2FLogo-BBVA.png?alt=media&token=9de52a68-0fff-494b-8731-83f50431e997"
// const img7 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Fpat-logo.png?alt=media&token=cc3d90fb-4dc5-457f-bd3e-61e333c86626"
// const img8 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Fescudo-de-armas.png?alt=media&token=2487b30a-4b8f-48c9-884e-2a9a8b42386d"
// const img9 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Fescudo-de-armas.png?alt=media&token=9f38c952-55fd-4351-9337-31a61fd89526"
// const img10 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Ftecnologico-de-monterrey-white.png?alt=media&token=77a33d1e-0ad5-4395-a2de-0705f538d129"
// const img11 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Flogos%20blancos%2Flogo4_metlife.png?alt=media&token=03bdb43f-4cc1-444a-8891-20a5bd3f2778"
// const img12 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2FGrupo_Sura_logo%201.png?alt=media&token=ba76ed61-7a5b-43c0-a084-e9c2e2171c5a"
// const img13 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Faaa.png?alt=media&token=f4cbd966-c57d-4b0b-9f21-c4e25d1d818a"
// const img14 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fdescarga%20(8).png?alt=media&token=09ab4224-34fa-4001-afff-decc51cdfd0a"
// const img15 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fdoka.png?alt=media&token=d12bcff7-dc7e-482d-971b-fba14358ae86"
// const img16 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fgpx.png?alt=media&token=93b3071e-2d2e-4de2-89e1-ecfe970de7bd"
// const img17 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fpga.png?alt=media&token=f56867c3-3619-4973-99fa-8bbf67c18ad6"
// const img18 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Frvm.png?alt=media&token=d4038fa5-b890-40ab-8f3c-6224e75afd20"
// const img19 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fswiss.png?alt=media&token=01c0ce2f-8ffc-4be3-b113-11a49e3bba17"

type AboutProps = {|
    link: string, 
|}


const About = ({ link }: AboutProps): React.Node => {
    return (
        <div className={classNames(styles.aboutContainer)}>
            <Fade bottom>
                <div className={classNames(styles.aboutContent)}>
                    <h2 className={classNames(styles.title)}>Más de 12 años de experiencia acompañando a nuestros clientes
                    </h2>
                    <div className={classNames(styles.box)}>
                        <img src={img1} />
                        <img src={img2} />
                        <img src={img3} />
                        <img src={img4} />
                        <img src={img5} />
                        <img src={img6} />
                        <img src={img7} />
                        <img src={img8} />
                        <img src={img9} />
                        <img src={img10} />
                        <img src={img11} />
                        <img src={img12} />
                        <img src={img13} />

                        <img src={img15} />

                        <img src={img16} />
                        <img src={img17} />
                        <img src={img18} />
                        <img src={img19} />
                        <img src={img20} />
                        <img src={img21} />
                        <img src={img22} />
                        <img src={img23} />
                        <img src={img24} />
                        <img src={img25} />
                        <img src={img26} />
                        <img src={img27} />
                        <img src={img28} />
                        <img src={img29} />
                        {/* <img src={img30} /> */}
                        <img src={img31} />
                        <img src={img32} />
                    </div>
                </div>
            </Fade >
        </div >
    )
}

export default About