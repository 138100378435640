
import * as React from 'react'
import classNames from 'classnames'
import Button from '../buttons/Button'
import Breadcrumb from './Breadcrumb'

import styles from './Header.module.css'
import { AppContext } from 'context/AppContext'

type HeaderProps = {
    variant: 'primary' | 'secondary',
    name?: string,
    coursesInProgress?: number,
    courseImg?: string,
}



const Header = ({ variant, courseImg }: HeaderProps): React.Node => {
    const [appData] = React.useContext(AppContext)
    const {
        user,
        coursesInProgress
    } = appData

    const welcomeHeader = (
        <div className={classNames(styles.coursesProfileHeader)}>
            <h2 className={classNames(styles.headerTitle)}>¡Bienvenid@ de nuevo, {user.displayName}!</h2>
            <p>
                {!coursesInProgress
                    ? 'No tienes cursos en progreso.'
                    : coursesInProgress === 1
                        ? `Tienes ${coursesInProgress} curso en progreso.`
                        : `Tienes ${coursesInProgress} cursos en progreso.`}
            </p>
            <Button variant='secondary' variantSize='large'>
                Explora más cursos
            </Button>
        </div>
    )

    const courseHeader = (
        <div
            className={classNames(styles.header)}
            style={{ background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${courseImg}) center/cover` }}
        >
            <div className={classNames(styles.breadcrumb)}>
                <Breadcrumb />
            </div>
        </div>
    )


    return (
        <div className={classNames(styles.headerContainer)}>
            {variant === 'primary' ? welcomeHeader : courseHeader}
        </div>

    )
}

export default Header