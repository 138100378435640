//@flow
import * as React from 'react'
// import NavBar from 'primitives/navbar/NavBar'
import classNames from 'classnames'

import styles from './PageFrame.module.css'
import Header from 'primitives/header/Header'
import SideBar from 'primitives/sideBar/SideBar'
import NavBar from 'primitives/navbar/NavBar'
import Footer from 'pages/home/Footer'

type Props = {
    children: React.Node,
    variant?: 'public' | 'private'
}

const PageFrame = ({
    children,
    variant = 'public'
}: Props): React.Node => {

    const publicLayout = (<> <NavBar /><article className={classNames({
        [styles.pageFrame]: variant === 'public',
    })}>

        {children}

    </article>
        <Footer />
    </>)

    const privateLayout = (<article className={classNames({
        [styles.pageFrame]: variant === 'public',
    })}>
        <Header variant='primary' name="bliss" coursesInProgress="3" />
        <SideBar />
        {children}
    </article>)

    switch (variant) {
        case 'private':
            return privateLayout
        default:
            return publicLayout
    }
}

export default PageFrame

