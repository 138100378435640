//flow
import * as React from 'react'
import classNames from 'classnames'
import styles from './CommentsCarousel.module.css'
import { Rate } from 'antd';
import Fade from 'react-reveal/Fade';
import Button from '../../primitives/buttons/Button'
import { Link } from 'react-router-dom';

const client = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/19621088_1334780026617382_7823503395334101987_o.jpeg?alt=media&token=50d41518-72e7-4dc5-87ee-e686e55683bd"
const teamwork = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fteamwork.svg?alt=media&token=1663cc4a-3cab-4fcb-ad48-108e6f940a78"
const photo = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fsport.png?alt=media&token=02feee1d-9db4-4928-8c09-a55d19c7ef74"
const gif = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fteamwork%20(1).gif?alt=media&token=6003746c-0ccc-4b38-acc4-4da0f3cf2068"
const img = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fimg-5.png?alt=media&token=7761cb1d-d5ec-49d0-a0f4-43ffd388ed80"
const man1 = "https://images.pexels.com/photos/925786/pexels-photo-925786.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
const quote = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fquote%20(1)%201.svg?alt=media&token=6a4981d7-5ce4-46ce-8ec0-72bd7ce63d6a"

const CommentsCarousel = (): React.Node => {
  return (
    <section>

      <div className={classNames(styles.boxs)}>
        <Fade bottom>
          <h2>Qué piensan nuestros clientes de nosotros</h2>
          <div className={classNames(styles.testimonials)}>
            <div className={classNames(styles.card)}>
              {/* <img className={classNames(styles.client)} src={man1} />
            <br /> */}
              <img className={classNames(styles.quote)} src={quote} />
              {/* <h3>Luis Enrique Lira</h3> */}
              <p>Dentro del programa de Coaching Deportivo logramos grandes resultados, logramos reavivar la pasión por lo que hacemos, conocernos a nosotros mismos, superar nuestros miedos y enfocarnos en nuestros objetivos, no solo de forma individual, sino como equipo.</p>
              <p className={classNames(styles.name)}>- Jesus Gallardo, Futbolista profesional
              </p>
            </div>
            <div className={classNames(styles.card)}>
              {/* <img className={classNames(styles.client)} src={man1} />
            <br /> */}
              <img className={classNames(styles.quote)} src={quote} />
              {/* <h3>Luis Enrique Lira</h3> */}
              <p>Como profesionales llegamos a olvidar la importancia de la salud mental, siendo algo fundamental para lograr nuestros objetivos. El coaching de Alto Rendimiento ha sido clave para lograr el equilibrio entre la salud física y emocional.</p>
              <p className={classNames(styles.name)}>- Psyco Clown, Luchador profesional </p>
            </div>
            <div className={classNames(styles.card)}>
              {/* <img className={classNames(styles.client)} src={man1} />
            <br /> */}
              <img className={classNames(styles.quote)} src={quote} />
              {/* <h3>Luis Enrique Lira</h3> */}
              <p>Mi experiencia con Potentiia ha sido reveladora, ha cambiado la forma de ver mi negocio y de verme a mí misma. El acompañamiento y el uso de herramientas ha marcado un antes y un después en mi carrera y en mi vida.</p>
              <p className={classNames(styles.name)}>- Arturo JTI,  Director Regional de Talento Humano de JTI.
              </p>
            </div>
          </div>
        </Fade>
      </div>

      <div className={classNames(styles.container)}>
        <Fade bottom>
          <div>
            <h2 className={classNames(styles.titleDescription)}>
              Desarrollo de equipos de Alto Rendimiento en tu organización
            </h2>
            <p className={classNames(styles.text)}>
              <strong>  Motivación, compromiso, enfoque a metas, concentración, gestión emocional o liderazgo son habilidades</strong> donde el Alto Rendimiento Deportivo y el campo militar ha sido pionero en encontrar la manera más rápida de desarrollarla.
            </p>
            <p className={classNames(styles.text)}>
              Asesorate con nosotros y en 20 minutos resuelve cómo vas a dar el siguiente paso.
            </p>
            <p className={classNames(styles.text)}>
              Tenemos <strong>diferentes formatos </strong> para diferentes necesidades.
            </p>
            <br />
            <Link to="/contacto">
              <Button variantSize='medium'>Quiero una asesoría de 20 minutos</Button>
            </Link>
          </div>
        </Fade>
        <Fade bottom>
          <img src={img} />
        </Fade>
      </div >
    </section>
  )
}

export default CommentsCarousel