//@flow
import * as React from 'react'
import classNames from 'classnames'
import styles from './Bfluency.module.css'
import { TeamOutlined, FlagOutlined, LineChartOutlined } from '@ant-design/icons'
import Button from 'primitives/buttons/Button'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom'
import PageFrame from 'primitives/layout/PageFrame'

const img1 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/img3.svg?alt=media&token=88729780-94e6-4e61-a39b-5459af4496b7"
const img2 = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/img4.svg?alt=media&token=ac748f49-4011-4652-85a9-91408482f61a"
const gif = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2FArrows.gif?alt=media&token=1008b665-7206-498f-801a-de16c4ee1dcb"
const arrow = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2FDown%20Arrow.gif?alt=media&token=6446bc62-22d6-434c-8349-0a621ed06677"
const bubble = "https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2FCircles%20Preloader.gif?alt=media&token=99548e92-1e33-4f93-a6de-0d06515dfc73"

const Bfluency = (): React.Node => {
    React.useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <PageFrame>
            <meta property="og:title" content="Sobre Potentiia " />
            <meta property="og:description"
                content="Acompañando a los equipos hasta su mejor versión a través de herramientas prácticas y aplicables provenientes del alto rendimiento deportivo." />

            <section className={classNames(styles.bfluencyContainer)}>
                <section className={classNames(styles.container)}>
                    <div className={classNames(styles.info)}>
                        <Fade bottom>
                            <h2>Acompañando a los equipos hasta su mejor versión</h2>
                            <p>A través de generación de espacios para aprender herramientas prácticas y aplicables provenientes del alto rendimiento deportivo y militar.
                            </p>
                            <div className={classNames(styles.data)}>
                                <div>
                                    <h3>+4000 </h3>
                                    <p>horas de coaching</p>
                                </div>
                                <div>
                                    <h3>+160 </h3>
                                    <p>compañías</p>
                                </div>
                                <div>
                                    <h3>+10,000 </h3>
                                    <p>personas entrenadas</p>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </section>
                <section className={classNames(styles.banner)}>
                    <Fade bottom>
                        <h2> <strong>Más de una década de historia, </strong> acompañando a equipos deportivos y empresariales a conseguir sus metas</h2>
                    </Fade>
                </section>
                <section className={classNames(styles.story)}>
                    <Fade bottom>
                        <div className={classNames(styles.storyCard)}>
                            <img className={classNames(styles.icon)} src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fgrowing-plant.svg?alt=media&token=89f4e9f5-6fb1-4b33-a1d9-70976e8f7b95" />
                            <h2>Nuestro comienzo</h2>
                            <p>Hace ya doce años que nos enamoramos de las posibilidades que la psicología del deporte y el alto rendimiento ofrece a todo aquel individuo o grupo que desee alcanzar su mejor versión. </p>
                            <p>Y lo mejor es que nos dimos cuenta muy rápido que daba igual que trabajásemos con atletas de élite, dueños o directores de empresa, militares o empleados en un gran corporativo. </p>
                            <p> <strong> Los principios del alto rendimiento, si se aplican de la manera correcta sirven para cualquier tipo de población.</strong></p>
                        </div>
                        <div className={classNames(styles.storyCard)}>
                            <img className={classNames(styles.icon)} src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fknowledge.svg?alt=media&token=dcd0eca6-1a90-47f5-afec-667c1bd27872" />
                            <h2>Nuestro conocimiento</h2>
                            <p>El entrenamiento mental a través de planes de acción bien estructurado es la clave definitiva que marca la diferencia entre los buenos de los mejores. </p>
                            <p>Nuestros productos están <strong> diseñados precisamente para que rompas la barrera de lo convencional y te adentres en el mundo de lo extraordinario, en el alto rendimiento.</strong> </p>
                        </div>
                        <div className={classNames(styles.storyCard)}>
                            <img className={classNames(styles.icon)} src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fhelping.svg?alt=media&token=312f6904-6bd6-4dd9-b68b-38a6cf8a4922" />
                            <h2>Nuestro crecimiento</h2>
                            <p>Y tras más de una  década formando personas, podemos decir orgullosamente que <strong>hemos ayudado a miles de personas
                                de todos los ámbitos</strong>  (deportivo, político, empresarial, individual, grupal) a conseguir sus objetivos utilizando para ello las herramientas que la psicología del deporte y el alto rendimiento ofrece a todo aquel que quiera mejorar su desempeño y alcanzar nuevas cotas de eficiencia</p>
                        </div>
                        <div className={classNames(styles.storyCard)}>
                            <img className={classNames(styles.icon)} src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2Fstartup%20(1).svg?alt=media&token=e3a5956b-4045-4385-945e-ec16def35621" />

                            <h2>Nuestro futuro</h2>
                            <p>Nuestra intención es seguir llevando al mundo la mayor cantidad posible de herramientas que <strong> ayuden a las personas a conseguir sus metas no solamente en el ámbito profesional sino también personal.</strong>
                            </p>
                            <p>Estamos convencidos que si hasta ahora hemos formado a miles de personas en la próxima década llegaremos a cientos de miles de personas.
                            </p>
                        </div>
                    </Fade>
                </section>
                <section className={classNames(styles.steps)}>
                    <Fade bottom>
                        <h2>Nuestro modelo</h2>

                        <div className={classNames(styles.stepsBox)}>
                            <div className={classNames(styles.box)}>
                                <div className={classNames(styles.number)}>
                                    <span>1</span>
                                    <h3>Aprendizaje <br />Experiencial
                                    </h3>
                                </div>

                                <p>Para lo que puedes leer por tu cuenta no nos necesitas. Las sesiones están construidas para vivir las herramientas y su aplicación.
                                </p>
                            </div>
                            <img src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2FArrow22.svg?alt=media&token=42e292e0-7066-4b5c-8fc4-1b16ad33dab3" />
                            <div className={classNames(styles.box)}>
                                <div className={classNames(styles.number)}>
                                    <span>2</span>
                                    <h3>Aplicación <br /> Inmediata
                                    </h3>
                                </div>

                                <p>Puedes aplicar cada herramienta al momento de aprenderla.
                                </p>
                            </div>
                            <img src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2FArrow22.svg?alt=media&token=42e292e0-7066-4b5c-8fc4-1b16ad33dab3" />

                            <div className={classNames(styles.box)}>
                                <div className={classNames(styles.number)}>
                                    <span>3</span>
                                    <h3>Eficacia
                                    </h3>
                                </div>

                                <p>No necesitas meses para ver un cambio. Nuestras sesiones están pensadas para generar cambios rápidos.
                                </p>
                            </div>
                            <img src="https://firebasestorage.googleapis.com/v0/b/bfluency-9ed7c.appspot.com/o/potentia%2Fimagenes%2FArrow22.svg?alt=media&token=42e292e0-7066-4b5c-8fc4-1b16ad33dab3" />

                            <div className={classNames(styles.box)}>
                                <div className={classNames(styles.number)}>
                                    <span>4</span>
                                    <h3>Propósito <br /> Claro
                                    </h3>
                                </div>

                                <p>Estamos hechos para movernos con propósito. La gente que tiene claro su por qué, es más eficiente. Nosotros trabajamos cada sesión con esta base.
                                </p>
                            </div>
                        </div>
                    </Fade>
                </section>
                <section className={classNames(styles.reasons)}>
                    <Fade bottom>
                        <h2>¿Por qué a través de herramientas que provienen del Alto Rendimiento Deportivo?</h2>
                        <p>Por dos razones fundamentales:</p>
                        <div className={classNames(styles.reasonsBox)}>
                            <div>Porque somos apasionados del deporte, el desarrollo personal, y el desafío de superar los límites. El alto rendimiento deportivo combina todos estos elementos en un enfoque práctico y efectivo.</div>
                            <img src={gif} />
                            <div>Las metodologías de alto rendimiento se diseñan con la necesidad de resultados casi inmediatos. Lo que no funciona, se descarta. Estas herramientas tienen en cuenta aspectos cruciales del comportamiento humano, lo que las hace prácticas y efectivas para alcanzar metas.</div>
                        </div>
                    </Fade>
                </section>

                {/* <section style={{ backgroundColor: "#FAFBFE" }} className={classNames(styles.reasons)}>
                    <h2>Nuestra Metodología: Aprendizaje Experiencial Y Herramientas Eficaces</h2>
                    <div className={classNames(styles.stepsBox)}>
                        <div className={classNames(styles.infoL)}>
                            <p>Nuestros formatos se adaptan a las diferentes necesidades de las personas, pero la base siempre es la misma: Aprendizaje experiencial, herramientas eficaces y aplicabilidad en tu campo.</p>
                            <p>Dentro de este marco, ofrecemos una gama de servicios que incluyen conferencias, talleres, asesoría individual, coaching de grupo y programas completos de desarrollo de equipos.
                            </p>
                            <Link to="/contacto">
                                <button className={classNames(styles.btnPrimary)}>Escríbenos</button>

                            </Link>
                        </div>
                        <div className={classNames(styles.infoR)}>
                            <img src={img1} />
                        </div>
                    </div>

                </section> */}

            </section>
        </PageFrame>
    )
}

export default Bfluency