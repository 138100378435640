//@flow
import * as React from 'react'
import classNames from 'classnames'
import { Form, Input } from 'antd'
import Button from '../../primitives/buttons/Button'
import styles from './ContactForm.module.css'
import PageFrame from 'primitives/layout/PageFrame'

type ContactFormProps = {
  preview?: boolean,
  widthImg?: string,
  heightImg?: string,
}
const contact = 'https://images.pexels.com/photos/3756042/pexels-photo-3756042.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'

const ContactForm = ({ preview = false, heightImg = '100%', widthImg = '100%' }: ContactFormProps): React.Node => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <PageFrame>
      <div className={classNames(styles.barra)}></div>
      <div className={classNames(styles.contactContainer)}>
        <div className={classNames(styles.contactContent)}>
          <h2 className={classNames(styles.contactTitle)}>¿Estás listo para potenciar tu rendimiento?</h2>
          <p className={classNames(styles.contactDescription)}>
            Contáctanos hoy para descubrir cómo podemos ayudarte a lograr tus metas a través del alto rendimiento deportivo y el desarrollo personal.
          </p>
          <Form layout='vertical'>
            <Form.Item name={['user', 'name']} label='Nombre' className={classNames(styles.input)}>
              <Input className={classNames(styles.contactInput)} placeholder='Ingresa tu nombre' />
            </Form.Item>
            <Form.Item name={['user', 'email']} label='Email' rules={[{ type: 'email' }]}>
              <Input className={classNames(styles.contactInput)} placeholder='ejemplo@potentiia.com' />
            </Form.Item>
            <Form.Item name={['user', 'comment']} label='¿En que servicios estás interesado?'>
              <Input.TextArea className={classNames(styles.contactInput)} rows={5} placeholder='Ingresa un mensaje' />
            </Form.Item>
          </Form>
          <div className={classNames(styles.contactBtn)}>
            <Button variantSize='large' htmlType='submit'>
              Enviar
            </Button>
          </div>
        </div>
        <div className={classNames(styles.contactImgContainer)}>
        </div>
      </div>
    </PageFrame>
  )
}
export default ContactForm
