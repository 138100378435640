//@flow
import * as React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { Image } from 'antd'
import { AuditOutlined, BulbOutlined, PoweroffOutlined, SmileOutlined } from '@ant-design/icons'

import styles from './SideBar.module.css'
import { AppContext } from '../../context/AppContext'


const SideBar = (): React.Node => {
  const [, , signOut] = React.useContext(AppContext)
  return (
    <div className={classNames(styles.siderContainer)}>
      <div className={classNames(styles.siderContent)}>
        <Image alt='bfluency logo' preview={false} className={classNames(styles.logo)} />
        <NavLink strict to='/perfil/mis-cursos' className={styles.links} activeClassName={styles.activeLink}>
          <AuditOutlined style={{ fontSize: '28px' }} />Mis cursos
        </NavLink>
        <NavLink to='perfil/test' className={styles.links} activeClassName={styles.activeLink}>
          <BulbOutlined style={{ fontSize: '28px' }} />Test
        </NavLink>
        <NavLink to='perfil/miperfil' className={styles.links} activeClassName={styles.activeLink}>
          <SmileOutlined style={{ fontSize: '28px' }} />Mi perfil
        </NavLink>
        <div className={styles.logOut}>
          <NavLink to='/' className={styles.links} activeClassName={styles.activeLink} onClick={signOut}>
            <PoweroffOutlined style={{ fontSize: '28px' }} />Cerrar sesión
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default SideBar