//@flow
import * as React from 'react'
import { useState } from 'react'
import classNames from 'classnames'
import { Form, Input, Tag } from 'antd'
import Button from '../../primitives/buttons/Button'

import styles from './AditionalForm.module.css'

const { CheckableTag } = Tag

const tagsData = ['Trabajo en equipo', 'Mejor comunicación', 'Generar confianza', 'Incremento de confianza',
'Aumento de resolución de conflictos', 'Manejo de crisis', 'Disminución de estrés', 'Salud']

type AditionalFormProps = {
  btnFunction: ()=> mixed}
const tagStyle = {
  border: '1px dashed #D9D9D9', 
  fontSize:'15px', 
  color:'#262626', 
  minWidth:'163px', 
  height:'40px', 
  padding: '8px 16px',
  margin:'0 3% 12px 0', 
  textAlign:'center',
}


const AditionalForm = ({btnFunction}: AditionalFormProps): React.Node => {
  const [selectedTag, setSelectedTag] = useState([])

  const handleChangeTags = (tag, checked) => {
    console.log(tag)
    setSelectedTag ( checked ? [...selectedTag, tag] : selectedTag.filter(t => t !== tag))
  }
  return (
    <>
      <p className={classNames(styles.aditionalDescription)}>
      Información adicional.
      </p>
      <Form layout='vertical'>
        <Form.Item name='position' label='¿Cuál es tu puesto en la compañía?'>
          <Input placeholder='Ingresa tu puesto' />
        </Form.Item>
        <Form.Item name='participants' label='¿Cuántas personas participarán en el programa?'>
          <Input placeholder='0' rules={[{ type: 'number' }]}/>
        </Form.Item>
        <Form.Item className={styles.tags} label='¿Cuáles son los objetivos del programa?'>
        {tagsData.map(tag => (
          <CheckableTag
           className={classNames(styles.objectivesTags)}
            key={tag}
            style={{...tagStyle}}
            checked={selectedTag.indexOf(tag) > -1}
            onChange={checked => handleChangeTags(tag,checked)}
          >
            {tag}
          </CheckableTag>
        ))}
        </Form.Item>
      </Form>
      <div className={classNames(styles.personalizedProgramButtons)}>
          <Button variantSize='small' variant='secondary' onClick={btnFunction}>Regresar</Button>
          <Button variantSize='small'>Enviar</Button>
      </div>
    </> 
  )
}

export default AditionalForm