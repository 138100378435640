import { createContext, useState } from "react";

let app = {
    loggedIn: false,
    token: null,
    user: null,
    promo: null
}
const token = localStorage.getItem('accessToken')
app.loggedIn = !!token
if (!!token) {
    app = JSON.parse(localStorage.getItem('appData'))
}

const AppContext = createContext(app)

const { Provider, Consumer } = AppContext

const AppContextProvider = ({ children, ...props }) => {
    const [state, setState] = useState(app)

    const setAppData = (updatedFields) => {
        const newData = { ...state, ...updatedFields, loggedIn: !!updatedFields.token }
        setState(newData)
        localStorage.setItem('appData', JSON.stringify(newData))
        localStorage.setItem('accessToken', newData.token)
    }

    const signOut = () => {
        localStorage.removeItem('appData')
        localStorage.removeItem('accessToken')
        setState({ ...app, loggedIn: false, token: null })
    }

    return <Provider value={[
        state,
        setAppData,
        signOut,
    ]}>
        {children}
    </Provider>
}

export { AppContextProvider, Consumer as AppContextConsumer, AppContext }